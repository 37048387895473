import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import Path from '../../services/path';
import * as CatchupHelper from '../../services/catchup-filter-service';
import CardGroup from '@bfbs/storybook/dist/components/organisms/card-group/card-group';

const Catchup = ({ count, teaser, station }) => {
  const data = useStaticQuery(graphql`
    query CatchUpPages {
      allBroadcast(
        filter: {
          field_values: { listen_again: { eq: true }, listen_again_url: { ne: null } }
          published: { eq: true }
        }
        sort: { order: DESC, fields: stop___date }
      ) {
        nodes {
          title
          description
          bfbs_id
          duration
          stop {
            date
            time: date(formatString: "k:mm")
            timezone
          }
          start {
            date
            time: date(formatString: "k:mm")
            timezone
          }
          station {
            bfbs_station_id
          }
        }
      }
    }
  `);

  /**
   * Format the broadcasts ready for the card group component.
   *
   * @param broadcasts
   * @param station
   * @returns {any}
   */
  const prepareCards = (broadcasts, station) => {
    const cards = [];
    const filterByStation = Boolean(station);
    const colorMatrix = {
      0: 1,
      1: 2,
      2: 3,
      3: 4,
      4: 3,
      5: 4,
      6: 2,
      7: 1,
      8: 2,
      9: 1,
      10: 4,
      11: 3,
      12: 4,
      13: 3,
      14: 1,
      15: 2,
    };
    let matrixCounter = 0;

    broadcasts.forEach((broadcast, key) => {
      if (!filterByStation || (station && broadcast.station && station.bfbs_id === broadcast.station.bfbs_station_id)) {
        cards.push({
          title: broadcast.title,
          rounded: false,
          href: Path.getCatchupPath(broadcast.title),
          colour: colorMatrix[matrixCounter],
        });
        matrixCounter++;
        if (key === 15) {
          matrixCounter = 0;
        }
      }
    });
    return count > 0 ? cards.slice(0, count) : cards;
  };

  let broadcasts = CatchupHelper.getCatchupBroadcasts(data.allBroadcast.nodes);
  broadcasts = CatchupHelper.getBroadcastPerShow(broadcasts);
  const cards = prepareCards(broadcasts, station);

  if (cards.length === 0) {
    return null;
  }
  return (
    <>
      <CardGroup heading={teaser && "Catch up"} customClasses="o-card-group--grid-4 o-card-group--catchup" cards={cards} ctaText={teaser ? 'See all catch up' : ''} ctaUrl={teaser ? Path.catchupList : null} />
    </>
  );
};

Catchup.propTypes = {
  count: PropTypes.number,
  teaser: PropTypes.bool,
};

Catchup.defaultProps = {
  count: 0,
  teaser: true,
};

export default Catchup;
