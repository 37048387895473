import React from 'react';
import { graphql } from 'gatsby';

import Path from '../../services/path';
import StationHelper from '../../services/station-helper';
import { prepareAllPresenterData } from '../../services/presenter-helper';
import { prepareBearerData } from '../../services/station-helper';

import Heading from '@bfbs/storybook/dist/components/atoms/heading/heading';
import SlimlineBannerBlock from '@bfbs/storybook/dist/components/molecules/slimline-banner-block/slimline-banner-block';
import Catchup from '../../components/catchup/catchup';
import GetInTouch from '@bfbs/storybook/dist/components/molecules/get-in-touch/get-in-touch';
import CardGroup from '@bfbs/storybook/dist/components/organisms/card-group/card-group';
import HowToListen from '@bfbs/storybook/dist/components/organisms/how-to-listen/how-to-listen';

import Layout from '../../components/layout/layout';
import SEO from '../../components/seo';
import Container from '../../components/container';
import NowPlaying from '../../components/station/now-playing';

const Station = ({ data: { station, allPresenter, allBroadcast }, path }) => {
  const { title, metadatastation } = station;
  const breadcrumbs = [
    { name: 'Radio', path: '/' },
    { name: 'All stations', path: '/stations' },
    { name: title, path: null },
  ];
  const hasPresenters = allPresenter.edges.length > 0;

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <SEO title={title} />

      <SlimlineBannerBlock
        background="background--tertiary-two"
        heading={station.title}
        icon="radio-white"
        image={station.metadatastation.svg ? station.metadatastation.svg : ''}
      />

      <Container>
        <div className="row--padding-y">
          <NowPlaying
            broadcastList={allBroadcast}
            stationId={station.id}
            scheduleUrl={Path.getStationSchedulePath(station.title)}
          />
        </div>
        <Catchup count={6} teaser={true} station={station} />
      </Container>
      {hasPresenters && (
        <CardGroup
          background="background--secondary-8"
          heading="Presenters"
          isSlider={false}
          cards={prepareAllPresenterData({ allPresenter: allPresenter })}
          customClasses="row--padding-y o-card-group--rounded"
        />
      )}
      <Container>
        <div className={hasPresenters && 'row--padding-top-40'}>
          <Heading>Get in touch</Heading>
          <GetInTouch
            text="Request a song, spread the word about a local event or just say hello."
            touchPoints={StationHelper.prepareGetInTouch(
              station.metadatastation.telephoneNumber,
              station.metadatastation.email,
              station.metadatastation.customUri
            )}
          />
        </div>
      </Container>

      {metadatastation.metadatabearer && (
        <Container customClasses="row--padding-y">
          <HowToListen
            title="How to listen"
            description="You can listen to all BFBS radio stations online, on our iPhone and Android app, and on your smart speaker."
            selectLocationDesc="Select your location to check if there’s also a local frequency you can tune into"
            locationFreqData={prepareBearerData(metadatastation.metadatabearer)}
          />
        </Container>
      )}
    </Layout>
  );
};

export default Station;

export const stationQuery = graphql`
  query StationQuery($id: String!, $start: Date!, $end: Date!) {
    station(bfbs_id: { eq: $id }) {
      id
      bfbs_id
      title
      metadatastation {
        logo: logo128x128
        svg: svg1by1
        telephoneNumber
        email
        customUri {
          uri
          description
          mime
        }
        metadatabearer {
          bfbs_id
          type
          name
          data {
            freq
          }
        }
      }
    }

    allBroadcast(
      sort: { fields: start___date, order: ASC }
      filter: { start: { date: { gte: $start, lt: $end } }, bfbs_station_id: { eq: $id } }
    ) {
      edges {
        node {
          title
          description
          presenter {
            title
          }
          start {
            date
            timezone
          }
          stop {
            date
            timezone
          }
        }
      }
    }

    allPresenter(
      sort: { fields: title, order: ASC }
      filter: { station: { elemMatch: { bfbs_id: { eq: $id } } }, active: { eq: true } }
    ) {
      edges {
        node {
          title
          field_values {
            image
          }
        }
      }
    }
  }
`;
