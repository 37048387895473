import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Path from '../../services/path';
import {getLiveBroadcast, getTimeTillNextUpdate} from '../../services/broadcasts';
import ListenNowBlock from '@bfbs/storybook/dist/components/molecules/listen-now-block/listen-now-block';
import {prepareTimezoneDescription, prepareTimezone} from "../../services/time";

const NowPlaying = ({ broadcastList, stationId, scheduleUrl }) => {
  const liveBroadcast = getLiveBroadcast(broadcastList);
  const [currentBroadcast, setLiveBroadcast] = useState(liveBroadcast);

  /**
   * Generate the broadcast start - stop text.
   *
   * @param liveBroadcast
   * @param liveBroadcast.start
   * @param liveBroadcast.start.timezone
   * @param liveBroadcast.stop
   * @returns {string}
   */
  const getStartStopTimeFormatted = liveBroadcast =>
    `${prepareTimezone(liveBroadcast.start).format('LT')}
        - ${prepareTimezone(liveBroadcast.stop).format('LT')}
        (${prepareTimezoneDescription(liveBroadcast.start.timezone)})`;

  /**
   * Set a timeout to update the currently live broadcast.
   *
   * @returns {number}
   */
  const startUpdateTimer = () => {
    const timeLeft = getTimeTillNextUpdate(broadcastList);

    if (timeLeft) {
      const timer = setTimeout(() => {
        setLiveBroadcast(getLiveBroadcast(broadcastList));
        startUpdateTimer();
      }, (timeLeft + 2) * 1000);
      return timer;
    }
  };

  /**
   * Create an array of broadcast hosts and links to their bio.
   *
   * @param presenters
   * @returns {*}
   */
  const preparePresenters = presenters => {
    return presenters.map(presenter => {
      return {
        href: Path.getPresenterPath(presenter.title),
        name: presenter.title,
      };
    });
  };

  useEffect(() => {
    const timer = startUpdateTimer();
    return () => clearTimeout(timer);
  }, []);

  return (
    <ListenNowBlock
      date={null}
      href={Path.getStationPlayerUrl(stationId)}
      modifier="rounded"
      presenters={currentBroadcast && currentBroadcast.presenter ? preparePresenters(currentBroadcast.presenter) : null}
      text="Listen Live"
      time={currentBroadcast && getStartStopTimeFormatted(currentBroadcast)}
      title={currentBroadcast && currentBroadcast.title}
      ctaUrl={scheduleUrl}
      ctaText="Schedule"
      divider={true}
    />
  );
};

NowPlaying.propTypes = {
  broadcastList: PropTypes.arrayOf(PropTypes.object).isRequired,
  stationId: PropTypes.string.isRequired,
};

export default NowPlaying;
