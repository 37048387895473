const Time = require('./time');

const moment = require('moment');
const _ = require('lodash');

// short_name values to only show the latest catch up episodes.
// "forces n" is currently for legacy short names.
const latestOnly = ['forcesn', 'bfbsnews', 'forces n'];

module.exports = {
  /**
   * Filter the broadcasts to remove any broadcast that has not happened yet.
   *
   * @param broadcasts
   * @returns {*}
   */
  getCatchupBroadcasts: broadcasts => {
    return broadcasts.filter(broadcast => {
      const stopTime = Time.prepareTimezone(broadcast.stop);

      return stopTime.format('X') < Time.now().format('X');
    });
  },

  /**
   *  Assume that the broadcasts are sorted in date descending order already.
   *
   * @param broadcasts
   */
  getBroadcastPerShow: broadcasts => {
    const distinct = [];
    broadcasts.forEach(item => {
      if (
        !distinct.find(row => {
          return row.title === item.title;
        })
      ) {
        distinct.push(item);
      }
    });
    return distinct;
  },

  /**
   * Filter the broadcast to be distinct based on the stream URL.
   *
   * @param broadcasts
   * @returns {[]}
   */
  distinctByListenAgainUrl: broadcasts => {
    return _.uniqBy(broadcasts, broadcast => {
      return broadcast.field_values.listen_again_url;
    });
  },

  /**
   * Return true if we should only display the latest catch up without "more episodes".
   *
   * @param broadcast
   * @returns boolean
   */
  onlyShowLatest: broadcast => {
    return latestOnly.indexOf(broadcast.short_name.toLowerCase()) !== -1;
  },
};
