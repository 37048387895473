const moment = require('moment');
const Time = require('./time');

module.exports = {
  /**
   * Get current live broadcast
   *
   * @param allBroadcast
   * @returns {*}
   */
  getLiveBroadcast: allBroadcast => {
    const liveBroadcast = allBroadcast.edges.filter(node =>
      Time.now().isBetween(Time.prepareTimezone(node.node.start), Time.prepareTimezone(node.node.stop))
    );
    return liveBroadcast.length > 0 ? liveBroadcast[0].node : false;
  },

  /**
   * Order the broadcasts by their timestamp.
   *
   * @param broadcasts
   */
  orderBroadcasts: broadcasts => {
    return broadcasts.sort((a, b) => {
      return a.start.timestamp < b.start.timestamp;
    });
  },

  /**
   * Gets the time till next update required.
   *
   * Will return either the time till the end of the current broadcast, or if no current
   * broadcast, the start of the next broadcast.
   *
   * @param allBroadcast
   * @returns {number}
   */
  getTimeTillNextUpdate: allBroadcast => {
    const currentBroadcast = module.exports.getLiveBroadcast(allBroadcast);

    if (currentBroadcast) {
      return Time.prepareTimezone(currentBroadcast.stop).unix() - Time.now().unix();
    }

    const nextBroadcast = allBroadcast.edges.filter(broadcast => {
      return Time.prepareTimezone(broadcast.node.start).isAfter(Time.now());
    })[0];

    return nextBroadcast ? Time.prepareTimezone(nextBroadcast.node.start).unix() - Time.now().unix() : false;
  },
};
